const secureTradingStyles = {
  "background-color-body": undefined,
  "color-body": undefined,
  "font-size-body": undefined,
  "line-height-body": "0",
  "space-inset-body": "0",
  "space-outset-body": "0",
  "background-color-input": undefined,
  "border-color-input": "#d9d9d9",
  "border-radius-input": "2px",
  "border-size-input": "1px",
  "color-input": undefined,
  "font-size-input": "16px",
  "line-height-input": 1.5715,
  "space-inset-input": "4px",
  "space-outset-input": "0px",
  "background-color-input-error": undefined,
  "border-color-input-error": undefined,
  "border-radius-input-error": undefined,
  "border-size-input-error": undefined,
  "color-input-error": undefined,
  "font-size-input-error": undefined,
  "line-height-input-error": undefined,
  "space-inset-input-error": undefined,
  "space-outset-input-error": undefined,
  "color-label": undefined,
  "font-size-label": "14px",
  "display-label": undefined,
  "line-height-label": undefined,
  "font-size-message": undefined,
  "line-height-message": undefined,
  "color-error": undefined,
};

export default secureTradingStyles;
