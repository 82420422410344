import { client } from "antdash";

const apply = (data) => client.post("apply", data);
const resetPassword = (data) => client.post("reset_password", data);
const confirmResetPassword = (data) =>
  client.post("confirm_reset_password", data);
const googlePlacesSuggestions = (data) =>
  client.get("google_places_suggestions", data);
const googlePlacesDetails = (data) => client.get("google_place_details", data);
const record_apply_page_visit = (data) =>
  client.post("record_apply_page_visit", data);

const userApi = {
  apply,
  resetPassword,
  confirmResetPassword,
  googlePlacesSuggestions,
  googlePlacesDetails,
  record_apply_page_visit,
};

export default userApi;
