import React, { useState } from "react";
import { Form, Input, Button, Typography, Space, message } from "antd";
import { MailOutlined, CheckCircleOutlined } from "@ant-design/icons";
import { useSubmit } from "antdash";
import { Link } from "react-router-dom";
import userApi from "../../api/user";

const { Text, Title } = Typography;

const ResetPasswordForm = ({ setResetPassword, setRegister }) => {
  const [errors, setErrors] = useState([]);
  const [notFoundEmail, setNotFoundEmail] = useState(null);
  const [emailSent, setEmailSent] = useState(null);

  const submit = (formData) => userApi.resetPassword(formData);
  const success = (res) => {
    if (res.data.exists) {
      message.success("Reset link sent.");
      setEmailSent(res.data.email);
    } else {
      message.warn("User not found.");
      setNotFoundEmail(res.data.email);
    }
  };
  const error = (res) => {
    setErrors(Object.values(res.data));
  };
  const { form, submitting, onFinish } = useSubmit(submit, success, error);

  if (emailSent) {
    return (
      <Space direction="vertical" size="large" style={{ color: "#fff" }}>
        <CheckCircleOutlined style={{ fontSize: 80 }} />
        <Title level={4} style={{ color: "#fff" }}>
          Email sent to {emailSent}
        </Title>

        <Text type="link" onClick={() => submit({ email: emailSent })}>
          Send again
        </Text>
      </Space>
    );
  }

  return (
    <Form form={form} onFinish={onFinish} autoComplete="off">
      <Form.Item
        name="email"
        rules={[{ required: true, message: "Please enter an email." }]}
      >
        <Input
          prefix={<MailOutlined className="site-form-item-icon" />}
          placeholder="Email"
          autoCapitalize="none"
        />
      </Form.Item>
      {notFoundEmail && (
        <div style={{ marginBottom: 24 }}>
          <Space>
            <Text type="danger">User not found: {notFoundEmail}</Text>
            <Link to="/#register">
              <Text style={{ color: "#fff" }}>Create Account?</Text>
            </Link>
          </Space>
        </div>
      )}
      {errors.map((x, index) => (
        <Text type="danger" key={index}>
          {x}
        </Text>
      ))}

      <Form.Item>
        <Space>
          <Link to="/">
            <Button>Back</Button>
          </Link>
          <Button type="primary" htmlType="submit" loading={submitting}>
            Reset Password
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

export default ResetPasswordForm;
