import React, { useContext, useState } from "react";
import { Form, Input, Button, Checkbox, Typography } from "antd";
import { LockOutlined, IdcardOutlined } from "@ant-design/icons";
import { authApi, useSubmit } from "antdash";
import UserContext from "../../context/UserContext";
import { Link } from "react-router-dom";
import logo from "../../../logo.svg";

const { Text } = Typography;

const LoginForm = () => {
  const [errors, setErrors] = useState([]);
  const { setUser } = useContext(UserContext);

  const submit = (formData) => authApi.login(formData);
  const success = (res, formData) => {
    setUser(res.data, formData.remember);
  };
  const error = (res) => {
    setErrors(Object.values(res.data));
  };
  const { form, submitting, onFinish } = useSubmit(submit, success, error);

  return (
    <>
      <img
        className="logo"
        src={logo}
        alt={"Fitzwilliam Sports"}
        style={{ height: 55, marginBottom: 30 }}
      />
      <Form
        form={form}
        name="login"
        onFinish={onFinish}
        autoComplete="off"
        style={{ marginTop: "1em" }}
      >
        <Form.Item
          name="username"
          rules={[
            {
              required: true,
              message: "Please enter your Customer ID or Email.",
            },
          ]}
        >
          <Input
            prefix={<IdcardOutlined className="site-form-item-icon" />}
            placeholder="Customer ID or Email"
            autoCapitalize="none"
          />
        </Form.Item>

        <Form.Item name="password" rules={[{ required: true }]}>
          <Input.Password
            prefix={<LockOutlined className="site-form-item-icon" />}
            placeholder="Password"
          />
        </Form.Item>

        {/* <div style={{ marginBottom: 20 }}>
        <Link to="reset">
        <Text style={{ color: "#fff", cursor: "pointer" }}>
        Forgot Password?
          </Text>
          </Link>
        </div> */}

        {errors.map((x, index) => (
          <Text type="danger" key={index}>
            {x}
          </Text>
        ))}

        <Form.Item name="remember" valuePropName="checked">
          <Checkbox className="login-form">Remember me</Checkbox>
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            loading={submitting}
          >
            Log In
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default LoginForm;

const styles = {
  text: {
    color: "#fff",
  },
};
