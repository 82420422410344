import React, { useContext, useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Checkbox,
  Typography,
  Radio,
  Select,
  Col,
  Row,
  Result,
  Card,
  AutoComplete,
  Spin,
} from "antd";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { useSubmit, InputMoney, getCurrencySymbol } from "antdash";
import { LIMIT_PERIOD_OPTIONS } from "betui";
import TextArea from "antd/lib/input/TextArea";
import userApi from "../api/user";
import logo from "../../fws-gradient.svg";
import countryList from "iso-3166-country-list";
// Internal: Hooks
import useGooglePlacesSuggestions from "../hooks/useGooglePlacesSuggestions";
import useGooglePlacesDetails from "../hooks/useGooglePlacesDetails";

const { Text } = Typography;

const debugDefaults = {
  first_name: "Test",
  last_name: "User",
  year: 1997,
  month: 1,
  day: 15,
  email: "r@4e76a5ed.net",
  phone: "864539333",
  extension: "+353",
  address: "Oakfield,\nKilliney Hill Road,\nA96YP49\nDublin",
  currency: "EUR",
  self_excluded_before: false,
  consent_to_marketing: false,
  terms_and_conditions: false,
};

// const months = [
//   { value: 1, label: "January" },
//   { value: 2, label: "February" },
//   { value: 3, label: "March" },
//   { value: 4, label: "April" },
//   { value: 5, label: "May" },
//   { value: 6, label: "June" },
//   { value: 7, label: "July" },
//   { value: 8, label: "August" },
//   { value: 9, label: "September" },
//   { value: 10, label: "October" },
//   { value: 11, label: "November" },
//   { value: 12, label: "December" },
// ];

const extensions = [
  { value: "+353", label: "+353" },
  { value: "+44", label: "+44" },
];

const RegisterForm = () => {
  const [errors, setErrors] = useState([]);
  const [showLimit, setShowLimit] = useState(false);
  const [currency, setCurrency] = useState("EUR");

  const [application, setApplication] = useState(null);

  const [depositLimit, setDepositLimit] = useState("");

  const submit = (formData) => {
    return userApi.apply(formData);
  };
  const success = (res, formData) => {
    console.log("RES", res);
    form.resetFields();
    setApplication(res.data);
  };
  const error = (res) => {
    console.log("ERROR:", res);
    setErrors(Object.values(res.data));
  };
  const { form, submitting, onFinish } = useSubmit(submit, success, error);

  const currentYear = new Date().getFullYear();

  // Logic related to address
  const [address, setAddress] = useState("");
  const [locale, setLocale] = useState("IE");
  const [selectedPlaceId, setSelectedPlaceId] = useState("");
  const { addressSuggestions, addressLoading, addressError } =
    useGooglePlacesSuggestions(address, locale);
  const { placeDetails, detailsLoading, detailsError } =
    useGooglePlacesDetails(selectedPlaceId);

  // Transform the list of countries for the Select component
  const countryOptions = countryList.map((country) => {
    // Check if the country is the UK by its code, and adjust the name
    if (country.code === "GB") {
      country.name = "United Kingdom";
    }
    return {
      value: country.code,
      label: country.name,
    };
  });

  // Sort so that Ireland and UK are the first options...And Ireland comes before UK..
  countryOptions.sort((a, b) => {
    if (a.value === "IE" && b.value !== "IE") return -1;
    if (b.value === "IE" && a.value !== "IE") return 1;
    if (a.value === "GB" && b.value !== "GB") return -1;
    if (b.value === "GB" && a.value !== "GB") return 1;
    return 0;
  });

  useEffect(() => {
    if (placeDetails) {
      form.setFieldsValue({
        address_1: placeDetails.address_1,
        address_2: placeDetails.address_2,
        address_3: placeDetails.address_3,
        address_4: placeDetails.address_4,
        postcode: placeDetails.postcode,
        // country: placeDetails.country,
      });
    }
  }, [placeDetails]);

  useEffect(() => {
    userApi.record_apply_page_visit();
  }, []);

  return (
    <div style={{ backgroundColor: "#fff", width: "100%" }}>
      {application === null ? (
        <div style={{ marginLeft: "auto", marginRight: "auto", maxWidth: 600 }}>
          <div style={{ marginLeft: "1em", marginRight: "1em" }}>
            <img
              className="logo"
              src={logo}
              alt={"Fitzwilliam Sports"}
              style={{ height: 55, marginBottom: 80, marginTop: 100 }}
            />
            <Form
              form={form}
              name="login"
              onFinish={onFinish}
              autoComplete="off"
              initialValues={{
                extension: "+353",
                deposit_limit_period: "DAILY",
                consent_to_marketing: false,
                terms_and_conditions: false,
                country: "IE",
              }}

              // initialValues={debugDefaults}
            >
              <Row gutter={[16, 0]}>
                <Col span={12}>
                  <Form.Item
                    name="first_name"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your first name",
                      },
                    ]}
                  >
                    <Input placeholder="First Name" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="last_name"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your last name",
                      },
                    ]}
                  >
                    <Input placeholder="Last Name" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="day"
                    rules={[{ required: true, message: "Please select a day" }]}
                  >
                    <Select
                      showSearch
                      optionFilterProp="label"
                      placeholder="DOB Day"
                      options={[...Array(32).keys()]
                        .slice(1)
                        .map((x) => ({ label: x, value: x }))}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="month"
                    rules={[
                      { required: true, message: "Please select a month" },
                    ]}
                  >
                    <Select
                      showSearch
                      optionFilterProp="label"
                      placeholder="Month"
                      // options={months}
                      options={[...Array(13).keys()]
                        .slice(1)
                        .map((x) => ({ label: x, value: x }))}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="year"
                    rules={[
                      { required: true, message: "Please select a year" },
                    ]}
                  >
                    <Select
                      showSearch
                      optionFilterProp="label"
                      placeholder="Year"
                      options={[...Array(currentYear - 18).keys()]
                        .slice(new Date().getFullYear() - 100)
                        .map((x) => ({ label: x, value: x }))}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item
                name="email"
                rules={[{ required: true, message: "Please enter an email" }]}
              >
                <Input
                  type="email"
                  // prefix={<MailOutlined />}
                  placeholder="Email"
                  autoCapitalize="none"
                />
              </Form.Item>
              <Row gutter={[16, 0]}>
                <Col span={8}>
                  <Form.Item
                    name="extension"
                    rules={[
                      { required: true, message: "Please select an extension" },
                    ]}
                  >
                    <Select options={extensions} />
                  </Form.Item>
                </Col>
                <Col span={16}>
                  <Form.Item
                    name="phone"
                    rules={[
                      {
                        required: true,
                        message: "Please enter a phone number",
                      },
                    ]}
                  >
                    <Input type="tel" placeholder="Mobile Phone Number" />
                  </Form.Item>
                </Col>
              </Row>

              <div
                style={{ textAlign: "start", marginTop: 5, marginBottom: 10 }}
              >
                <Text style={{ fontSize: 16 }}>Address</Text>
              </div>

              <Row gutter={[8, 0]}>
                <Col span={16}>
                  <AutoComplete
                    options={addressSuggestions.map((suggestion) => ({
                      value: suggestion.description,
                      label: suggestion.description,
                      key: suggestion.place_id,
                    }))}
                    onSearch={setAddress}
                    onSelect={(value, option) => setSelectedPlaceId(option.key)}
                    placeholder="Start typing your address"
                    notFoundContent={
                      addressLoading ? <Spin size="small" /> : null
                    }
                    style={{ width: "100%", marginBottom: 10 }}
                  />
                </Col>
                <Col span={8}>
                  <Form.Item
                    style={{ marginBottom: 8 }}
                    name="country"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Select
                      style={{ width: "100%" }}
                      onChange={(val) => setLocale(val)}
                      options={countryOptions}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    style={{ marginBottom: 8 }}
                    name="address_1"
                    rules={[
                      {
                        required: true,
                        message: "This field is required",
                      },
                    ]}
                  >
                    <Input placeholder="Address Line 1" />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    style={{ marginBottom: 8 }}
                    name="address_2"
                    rules={[
                      {
                        required: true,
                        message: "This field is required",
                      },
                    ]}
                  >
                    <Input placeholder="Address Line 2" />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item name="address_3" style={{ marginBottom: 8 }}>
                    <Input placeholder="Address Line 3 (Optional)" />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item name="address_4" style={{ marginBottom: 8 }}>
                    <Input placeholder="Address Line 4 (Optional)" />
                  </Form.Item>
                </Col>

                <Col span={24} style={{ marginBottom: 8 }}>
                  <Form.Item
                    name="postcode"
                    rules={[
                      { required: true, message: "Please enter a postcode" },
                    ]}
                  >
                    <Input placeholder="Postcode" />
                  </Form.Item>
                </Col>
              </Row>

              <div style={{ marginTop: 20 }}>
                <Text style={{ fontSize: 16 }}>Select Currency...</Text>
                <Form.Item
                  name="currency"
                  rules={[
                    { required: true, message: "Please select a currency." },
                  ]}
                >
                  <Radio.Group
                    buttonStyle="solid"
                    style={{ marginTop: 10 }}
                    onChange={(v) => {
                      setCurrency(v.target.value);
                    }}
                  >
                    <Radio.Button value={"EUR"}>EUR (€)</Radio.Button>
                    <Radio.Button value={"GBP"}>GBP (£)</Radio.Button>
                  </Radio.Group>
                </Form.Item>
              </div>

              <Row gutter={[16, 16]}>
                <Col span={24} align="left">
                  <Button
                    icon={showLimit ? <MinusOutlined /> : <PlusOutlined />}
                    onClick={() => {
                      console.log(form);
                      form.setFieldsValue({ deposit_limit: undefined });
                      setShowLimit(!showLimit);
                    }}
                  >
                    Add a Deposit Limit
                  </Button>
                </Col>
                {showLimit && (
                  <>
                    <Col span={14}>
                      <Form.Item
                        name="deposit_limit"
                        rules={[{ required: false }]}
                      >
                        <InputMoney
                          symbol={getCurrencySymbol(currency)}
                          onChange={(v) => setDepositLimit(v.target.value)}
                          placeholder="Deposit Limit"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={10}>
                      <Form.Item
                        name="deposit_limit_period"
                        rules={[{ required: true }]}
                      >
                        <Select
                          options={LIMIT_PERIOD_OPTIONS}
                          disabled={depositLimit === ""}
                        />
                      </Form.Item>
                    </Col>
                  </>
                )}
              </Row>

              <div style={{ textAlign: "start", marginTop: 20 }}>
                <Text style={{ fontSize: 16 }}>
                  Have you self excluded from any gambling establishment before?
                </Text>
                <Form.Item
                  name="self_excluded_before"
                  rules={[
                    { required: true, message: "Please select an answer" },
                  ]}
                >
                  <Radio.Group buttonStyle="solid" style={{ marginTop: 10 }}>
                    <Radio.Button value={true}>Yes</Radio.Button>
                    <Radio.Button value={false}>No</Radio.Button>
                  </Radio.Group>
                </Form.Item>
              </div>

              <Form.Item name="message" rules={[{ required: false }]}>
                <TextArea
                  placeholder="Add a message..."
                  autoSize={{ minRows: 2, maxRows: 6 }}
                />
              </Form.Item>

              <Form.Item
                name="consent_to_marketing"
                valuePropName="checked"
                style={{ marginTop: 20, textAlign: "start", marginBottom: 0 }}
              >
                <Checkbox>Consent to marketing</Checkbox>
              </Form.Item>

              <Form.Item
                name="terms_and_conditions"
                valuePropName="checked"
                style={{ marginTop: 0, textAlign: "start" }}
                rules={[
                  {
                    validator: (_, value) =>
                      value
                        ? Promise.resolve()
                        : Promise.reject(
                            new Error(
                              "You must agree to our terms and conditions to apply."
                            )
                          ),
                  },
                ]}
              >
                <Checkbox>
                  I accept the terms, conditions and rules of Fitzwilliam
                  Sports.
                </Checkbox>
              </Form.Item>
              {/* 
      {errors.map((x, index) => (
        <Text type="danger" key={index}>
        {x}
        </Text>
      ))} */}

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  size="large"
                  loading={submitting}
                  style={{ marginTop: 20, marginBottom: 40 }}
                >
                  Apply Now
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      ) : (
        <Result
          style={{ marginLeft: "auto", marginRight: "auto", maxWidth: 500 }}
          status="success"
          title="Application Received"
          subTitle={`Thank you for applying to Fitzwilliam Sports. Our team is reviewing your application and will be in touch shortly.`}
        />
      )}
    </div>
  );
};

export default RegisterForm;
