// import "antd/dist/antd.css";
import "antd/dist/antd.variable.min.css";
import "./App.css";
import { useAuthStorage, PasswordResetRequired } from "antdash";
import logo from "./logo.svg";
import validateUser from "./app/tools/validateUser";
import AppNavigator from "./app/AppNavigator";
import TransferScreenLoading from "./app/routes/Transfer";
import TransferSuccess from "./app/routes/TransferSuccess";
import Apply from "./app/routes/Apply";
import UserContext from "./app/context/UserContext";
import ResetPasswordForm from "./app/components/forms/ResetPasswordForm";
import ConfirmResetPasswordForm from "./app/components/ConfirmResetPasswordForm";
import AuthNavigator from "./app/AuthNavigator";
import LoginRegister from "./app/components/forms/LoginRegister";
import LoginForm from "./app/components/forms/LoginForm";
import { ConfigProvider } from "antd";

ConfigProvider.config({
  theme: {
    // primaryColor: "#2c213d",
    primaryColor: "#44355c",
  },
});

const routes = {
  TRANSFER: {
    title: "Transfer",
    icon: null,
    path: "/",
    exact: true,
    component: TransferScreenLoading,
    menu: true,
    superuserOnly: false,
  },
  TRANSFER_SUCCESS: {
    title: "Transfer Success",
    icon: null,
    path: "/payment_success/:uuid",
    exact: true,
    component: TransferSuccess,
    menu: false,
    superuserOnly: false,
  },
  REGISTER: {
    title: "Apply",
    path: "/apply",
    exact: true,
    component: function ToApply() {
      const { removeUser } = useAuthStorage();
      removeUser();
      window.location.reload();
      return <></>;
    },
  },
};

const authRoutes = {
  LOGIN_REGISTER: {
    title: "Login or Resister",
    path: "/",
    exact: true,
    component: LoginForm,
    // component: LoginRegister,
  },
  REGISTER: {
    title: "Apply",
    path: "/apply",
    exact: true,
    // component: LoginForm,
    component: Apply,
  },
  TRANSFER_SUCCESS: {
    title: "Transfer Success",
    icon: null,
    path: "/payment_success/:uuid",
    exact: true,
    component: TransferSuccess,
    menu: false,
    superuserOnly: false,
  },
  // RESET_PASSWORD: {
  //   title: "Reset Password",
  //   path: "/reset",
  //   exact: true,
  //   component: ResetPasswordForm,
  // },
  // CONFIRM_RESET_PASSWORD: {
  //   title: "Confirm Reset Password",
  //   path: "/reset/:uid/:token",
  //   exact: true,
  //   component: ConfirmResetPasswordForm,
  // },
};

function App() {
  const { user, setUser, removeUser, updateUserSettingsLocal } =
    useAuthStorage();

  return (
    <UserContext.Provider value={{ user, setUser, logout: removeUser }}>
      {validateUser(user) ? (
        <AppNavigator
          logo={logo}
          routes={routes}
          user={user}
          logout={removeUser}
        />
      ) : (
        <AuthNavigator
          logo={logo}
          logoAlt={"Fitzwilliam Sports"}
          routes={authRoutes}
        />
      )}
    </UserContext.Provider>
  );
}

export default App;
