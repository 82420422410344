import React, { useState, useContext } from "react";
import Script from "react-load-script";
import { upperFirst } from "lodash";
import {
  Form,
  Typography,
  Button,
  Radio,
  Space,
  Checkbox,
  Divider,
  Popover,
  Select,
} from "antd";
import {
  PlusOutlined,
  CreditCardOutlined,
  BankOutlined,
  QuestionCircleFilled,
} from "@ant-design/icons";
import { InputMoney, getCurrencySymbol, Money, useApi } from "antdash";
import paymentsApi from "../api/payments";
import UserContext from "../context/UserContext";
import secureTradingStyles from "../tools/secureTradingStyles";
import Splash from "../components/Splash";
const { Title, Text } = Typography;

const METHODS = {
  BANK: "BANK",
  CARD: "CARD",
  AIB: "AIB",
};

const AIB_FORM_ID = "aib_form";

const AboutOpenPayments = ({ bankOptions }) => (
  <Space direction="vertical">
    <Text type="secondary">
      Secure, hassle free payment
      <br /> through your banking app.
    </Text>
    <Text type="secondary">Supported banks:</Text>
    <Space align="center">
      {bankOptions.map(({ name, logo }, index) => (
        <img key={index} src={logo} alt={name} style={styles.bankLogo} />
      ))}
    </Space>
  </Space>
);

const TransferScreenLoading = () => {
  const cof = useApi({ apiFunc: paymentsApi.getCOF });
  const bankOptions = useApi({ apiFunc: paymentsApi.getBankOptions });
  if (cof.data === null || bankOptions.data === null) {
    return <Splash />;
  } else {
    return (
      <Transfer
        cof={cof.data}
        bankOptions={bankOptions.data.banks}
        defaultBank={bankOptions.data.default}
      />
    );
  }
};

const Transfer = ({ cof, bankOptions, defaultBank }) => {
  const { user } = useContext(UserContext);

  const [paymentFormVisible, setPaymentFormVisible] = useState(false);
  const [method, setMethod] = useState(METHODS.BANK); // Must be banking as option only available both currencies
  const [amount, setAmount] = useState(null);
  const [useCOF, setUseCOF] = useState(cof.length > 0);
  const [cofIndex, setCofIndex] = useState(0);
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);

  console.log(bankOptions);

  const handleSubmit = async (fieldData) => {
    setLoading(true);
    if (method === METHODS.CARD) {
      const updatedUseCOF = fieldData.card > -1;
      setCofIndex(fieldData.card);
      const init = async () => {
        const res = await paymentsApi.getJWT({
          amount: fieldData.amount,
          save_card: false,
          cof_index: updatedUseCOF ? fieldData.card : undefined,
        });
        // console.log("TOKEN:", res.data.token);
        if (res.ok) {
          setAmount(fieldData.amount);
          setUseCOF(updatedUseCOF);
          setPaymentFormVisible(true);
          (function () {
            var st = window.SecureTrading({
              jwt: res.data.token,
              fieldsToSubmit: updatedUseCOF ? ["securitycode"] : undefined,
              livestatus: process.env.NODE_ENV === "development" ? 0 : 1,
              styles: secureTradingStyles,
              buttonId: "pay-btn",
            });
            st.Components();
            st.on("success", () => console.log("Success"));
            window.st = st;
          })();
        } else {
          // console.log("ERROR:", res);
          setErrors(
            Object.entries(res.data).map((x) => `${upperFirst(x[0])}: ${x[1]}`)
          );
        }
      };
      await init();
      setLoading(false);
    } else if (method === METHODS.AIB) {
      const res = await paymentsApi.getAibForm(fieldData.amount);
      console.log("AIB RES:", res);
      if (res.ok) {
        const aibForm = document.getElementById(AIB_FORM_ID);
        aibForm.innerHTML = res.data;
        aibForm.submit();
      } else {
        setLoading(false);
      }
    } else {
      const res = await paymentsApi.getFireLink(
        fieldData.amount,
        fieldData.bank_id
      );
      if (res.ok) {
        console.log("Pay by transfer", res.data.link);
        window.location.href = res.data.link;
      } else if (res.status === 400) {
        setErrors(
          Object.entries(res.data).map((x) => `${upperFirst(x[0])}: ${x[1]}`)
        );
      } else {
        setErrors(["Error Creating Payment"]);
      }
      setLoading(false);
    }
  };

  const updateToken = async (amount, save_card) => {
    const res = await paymentsApi.getJWT({ amount, save_card });
    // console.log("UPDATED TOKEN:", res.data.token);
    if (res.ok) {
      window.st.updateJWT(res.data.token);
    } else {
      console.log("ERROR:", res);
    }
    return res.ok;
  };

  const [saveCard, setSaveCard] = useState(false);
  const toggleSaveCard = () => {
    const value = !saveCard;
    setSaveCard(value);
    updateToken(amount, value);
  };

  return (
    <>
      {!paymentFormVisible ? (
        <div style={styles.container}>
          <Title level={4} style={styles.title}>
            Deposit Funds:
          </Title>
          <Form
            onFinish={handleSubmit}
            initialValues={{
              amount: amount,
              card: cofIndex,
              bank_id: defaultBank,
            }}
            style={{ width: 300 }}
          >
            <Form.Item
              name="amount"
              rules={[{ required: true, message: "Please enter an amount." }]}
            >
              <InputMoney
                symbol={getCurrencySymbol(user.customer.currency)}
                autoComplete={"off"}
              />
            </Form.Item>
            <>
              <Space
                direction="vertical"
                align="center"
                style={{ width: "100%" }}
              >
                <Text type="secondary">Select a payment method:</Text>
                <Radio.Group
                  defaultValue={method}
                  onChange={(e) => setMethod(e.target.value)}
                >
                  <Radio value={METHODS.BANK}>
                    <BankOutlined style={{ marginRight: ".5em" }} />
                    Open Banking
                    <Popover
                      title="Try Open Banking"
                      content={<AboutOpenPayments bankOptions={bankOptions} />}
                      style={{ maxWidth: 250 }}
                    >
                      <QuestionCircleFilled style={{ marginLeft: ".5em" }} />
                    </Popover>
                  </Radio>
                  {user.customer.currency === "EUR" ? (
                    <Radio value={METHODS.CARD}>
                      <CreditCardOutlined style={{ marginRight: ".5em" }} />
                      Debit Card
                    </Radio>
                  ) : (
                    <Radio value={METHODS.AIB}>
                      <CreditCardOutlined style={{ marginRight: ".5em" }} />
                      Debit Card
                    </Radio>
                  )}
                </Radio.Group>
              </Space>
              <Divider />
            </>
            {method === METHODS.CARD ? (
              cof.length > 0 && (
                <Form.Item name="card">
                  <Radio.Group>
                    <Space direction="vertical" size="small">
                      {cof.map((item, index) => (
                        <Radio value={index} key={index}>
                          <CreditCardOutlined style={{ marginRight: ".5em" }} />{" "}
                          {item.card_hint} ({item.card_type})
                        </Radio>
                      ))}
                      <Radio value={-1}>
                        <PlusOutlined style={{ marginRight: ".5em" }} /> New
                        Card
                      </Radio>
                    </Space>
                  </Radio.Group>
                </Form.Item>
              )
            ) : method === METHODS.AIB ? (
              <form
                id={AIB_FORM_ID}
                method="post"
                action="https://www.ipg-online.com/connect/gateway/processing"
              ></form>
            ) : (
              <Form.Item name="bank_id">
                <Select>
                  {bankOptions.map((item, index) => (
                    <Select.Option value={item.id} key={index}>
                      <img
                        src={item.logo}
                        alt={item.name}
                        style={{
                          ...styles.bankLogo,
                          marginRight: "1em",
                        }}
                      />
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}

            {errors && (
              <div style={{ marginBottom: 20 }}>
                {errors.map((x, index) => (
                  <Text type="danger" key={index}>
                    {x}
                    <br />
                  </Text>
                ))}
              </div>
            )}
            <Form.Item name="amount" style={{ textAlign: "center" }}>
              <Button htmlType="submit" loading={loading}>
                Continue
              </Button>
            </Form.Item>
          </Form>
        </div>
      ) : (
        <div style={styles.container}>
          <div id="st-notification-frame"></div>
          <Title level={4} style={styles.title}>
            Deposit: <Money amount={amount} currency={user.customer.currency} />{" "}
          </Title>
          {useCOF && (
            <Space style={styles.title}>
              <Text>
                Pay with: {cof[cofIndex].card_type} {cof[cofIndex].card_hint}
              </Text>
            </Space>
          )}
          <form
            id="st-form"
            action={`${process.env.REACT_APP_BACKEND_URL}payment_submit`}
            method="POST"
          >
            {!useCOF && (
              <>
                <div id="st-card-number" style={styles.formItem}></div>
                <div id="st-expiration-date" style={styles.formItem}></div>
              </>
            )}
            <div id="st-security-code" style={styles.formItem}></div>
            {!useCOF && (
              <Space style={{ ...styles.btnContainer, ...styles.title }}>
                <Text>Save details for future payments?</Text>
                <Checkbox checked={saveCard} onClick={toggleSaveCard} />
              </Space>
            )}
            <div style={styles.btnContainer}>
              <Space>
                <Button onClick={() => setPaymentFormVisible(false)}>
                  Back
                </Button>
                <Button
                  id="pay-btn"
                  htmlType="submit"
                  type="primary"
                  style={styles.btn}
                  disabled
                >
                  Pay
                </Button>
              </Space>
            </div>
          </form>
        </div>
      )}
      <Script url="https://webservices.securetrading.net/js/v3/st.js" />
    </>
  );
};

export default TransferScreenLoading;

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "70vh",
  },
  title: {
    marginBottom: "1.5em",
  },
  formItem: {
    height: 90,
  },
  btnContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  btn: {
    width: "10em",
  },
  bankLogo: {
    height: 35,
    padding: "0.25em",
  },
};
