const validateUser = (user) => {
  if (user === null) {
    return false;
  }
  if (user.username === undefined) {
    return false;
  }
  if (user.token === undefined) {
    return false;
  }
  if (user.remember === undefined) {
    return false;
  }
  if (user.settings === undefined) {
    return false;
  }
  if (user.settings.default_event_type === undefined) {
    return false;
  }
  if (user.settings.default_bet_input === undefined) {
    return false;
  }
  if (user.settings.display_currency === undefined) {
    return false;
  }
  if (user.settings.require_password_reset === undefined) {
    return false;
  }
  return true;
};

export default validateUser;
