import React from "react";
import { Route, Link } from "react-router-dom";
import { Layout, Menu, Dropdown } from "antd";
import { DownOutlined, LogoutOutlined } from "@ant-design/icons";
import prettyUsername from "./tools/prettyUsername";

const { Header, Content, Footer } = Layout;

function AppNavigator({ logo, routes, user, logout }) {
  const userMenu = (
    <Menu>
      <Menu.Item key="logout" onClick={logout} icon={<LogoutOutlined />}>
        Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <Layout className="site-layout" style={{ minHeight: "100vh" }}>
      <Header className="site-layout-background" style={styles.header}>
        <div className="logo-container trigger">
          <Link to="/">
            <img className="logo-mobile" src={logo} alt="Bookie" />
          </Link>
        </div>
        <div className="trigger" style={{ textAlign: "end" }}>
          <Dropdown overlay={userMenu} trigger={["click"]}>
            <small>
              {user.username} <DownOutlined />
            </small>
          </Dropdown>
        </div>
      </Header>
      <Content style={styles.content}>
        {Object.values(routes).map((route) => (
          <Route
            key={route.path}
            path={route.path}
            exact={route.exact}
            component={route.component}
          />
        ))}
      </Content>
      <Footer style={styles.footer}>
        Fitzwilliam Sports Ltd. © {new Date().getFullYear()}
      </Footer>
    </Layout>
  );
}

const styles = {
  header: {
    position: "fixed",
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    padding: 0,
    color: "white",
    zIndex: 4,
  },
  content: {
    marginTop: 64,
    padding: 16,
    backgroundColor: "#f0f2f5",
  },
  footer: {
    textAlign: "center",
  },
};

export default AppNavigator;
