import { useState, useEffect } from "react";
import userApi from "../api/user";

const useGooglePlacesDetails = (placeId) => {
  const [placeDetails, setPlaceDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const extractDetails = (addressComponents) => {
    let addresses = {
      address_1: "",
      address_2: "",
      address_3: "",
      address_4: "",
      postcode: "",
      country: "",
    };

    // Temporary holders for address components
    let streetNumber = "";
    let streetAddress = "";

    // Loop through the address components to extract relevant data
    addressComponents.forEach((component) => {
      if (component.types.includes("street_number")) {
        streetNumber = component.long_name;
      } else if (
        component.types.includes("route") ||
        component.types.includes("street_address")
      ) {
        streetAddress = component.long_name;
      } else if (component.types.includes("postal_code")) {
        addresses.postcode = component.long_name;
      } else if (component.types.includes("country")) {
        addresses.country = component.long_name;
      } else {
        // Assign other types of components to the address fields
        if (!addresses.address_2) {
          addresses.address_2 = component.long_name;
        } else if (!addresses.address_3) {
          addresses.address_3 = component.long_name;
        } else if (!addresses.address_4) {
          addresses.address_4 = component.long_name;
        }
      }
    });

    // Check if both streetNumber and route are present
    if (streetNumber && streetAddress) {
      addresses.address_1 = `${streetNumber} ${streetAddress}`;
    } else {
      addresses.address_1 = streetNumber || streetAddress;
    }
    return addresses;
  };

  const fetchPlaceDetails = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await userApi.googlePlacesDetails({
        place_id: placeId,
      });
      if (response && response.data) {
        const addressComponents = response.data.result.address_components;
        const extractedDetails = extractDetails(addressComponents);
        setPlaceDetails(extractedDetails);
      }
    } catch (error) {
      console.error("Error fetching Google Places details:", error);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (placeId) {
      fetchPlaceDetails();
    }
  }, [placeId]);

  return {
    placeDetails,
    detailsLoading: loading,
    detailsError: error,
  };
};

export default useGooglePlacesDetails;
