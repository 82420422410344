import { useState, useEffect } from "react";
import userApi from "../api/user";

const useGooglePlacesSuggestions = (address, locale) => {
  const [suggestions, setSuggestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchSuggestions = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await userApi.googlePlacesSuggestions({
        query: address,
        locale: locale,
      });
      if (response && response.data) {
        const filteredSuggestions = response.data.predictions.map((item) => ({
          description: item.description,
          place_id: item.place_id,
        }));
        setSuggestions(filteredSuggestions);
      }
    } catch (error) {
      console.error("Error fetching Google Places suggestions:", error);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (address && address.length > 3) {
      fetchSuggestions();
    }
  }, [address, locale]);

  return {
    addressSuggestions: suggestions,
    addressLoading: loading,
    addressError: error,
  };
};

export default useGooglePlacesSuggestions;
