import React from "react";
import { useParams, useHistory, Link } from "react-router-dom";
import { Typography, Result, Button, Space, Card } from "antd";
import { SyncOutlined } from "@ant-design/icons";
import { useApi, Money } from "antdash";
import paymentsApi from "../api/payments";
import Splash from "../components/Splash";

const { Text } = Typography;

const statusMapper = {
  FAILED: { status: "error", title: "Error", wording: "failed." },
  PENDING: {
    status: "warning",
    icon: <SyncOutlined />,
    title: "Pending",
    wording: "is complete pending final authorisation from your bank.",
  },
  COMPLETE: {
    status: "success",
    title: "Received",
    wording: "had been received.",
  },
};

const TransferSuccess = () => {
  const { uuid } = useParams();
  const history = useHistory();

  const conf = useApi({
    apiFunc: paymentsApi.getConfirmation,
    defaultParams: { uuid },
    debug: true,
  });

  if (conf.error) {
    history.push("/");
  }

  if (!conf.data) {
    return <Splash />;
  }

  const modalConf = statusMapper[conf.data.status];

  return (
    <Card style={styles.container}>
      <Result
        status={modalConf.status}
        title={`Payment ${modalConf.title}`}
        icon={modalConf?.icon && <SyncOutlined />}
        subTitle={
          <Text>
            Your payment of{" "}
            <Money
              amount={conf.data?.amount}
              currency={conf.data?.amount_currency}
            />{" "}
            {modalConf.wording}
            {conf.data?.message !== "" && (
              <>
                <br />
                {conf.data?.message}
              </>
            )}
          </Text>
        }
        extra={
          conf.data && (
            <Space direction="vertical">
              <Text type="secondary">Transfer reference: {conf.data.id}</Text>
              {modalConf.status === "error" ? (
                <Link to="/">Return to Payments Home</Link>
              ) : (
                <Link to="/">Return to Payments Home</Link>
              )}
            </Space>
          )
        }
      />
    </Card>
  );
};

export default TransferSuccess;

const styles = {
  container: {
    minHeight: "70vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};
