import { client } from "antdash";

const getCOF = () => client.get("credentials_on_file");

const getJWT = (data) => client.post("payment_token", data);

const getConfirmation = (data) => client.get("payment_confirmation", data);

const getBankOptions = () => client.get("fire_bank_options");

const getFireLink = (amount, bank_id) =>
  client.post("fire_payment_link", { amount, bank_id });

const getAibForm = (amount) => client.post("aib_payment_form", { amount });

const paymentsApi = {
  getCOF,
  getJWT,
  getConfirmation,
  getBankOptions,
  getFireLink,
  getAibForm,
};

export default paymentsApi;
