import React, { useContext, useState } from "react";
import {
  Form,
  Input,
  Button,
  Checkbox,
  Typography,
  Card,
  Space,
  message,
} from "antd";
import { MailOutlined, LockOutlined, IdcardOutlined } from "@ant-design/icons";
import { useSubmit } from "antdash";
import UserContext from "../../context/UserContext";
import userApi from "../../api/user";
import { Link } from "react-router-dom";

const { Text } = Typography;

const RegisterForm = () => {
  const [errors, setErrors] = useState([]);
  const { setUser } = useContext(UserContext);
  const [notFoundCid, setNotFoundCid] = useState(null);

  const submit = (formData) => userApi.register(formData);
  const success = (res, formData) => {
    setUser(res.data, formData.remember);
  };
  const error = (res) => {
    if (res.status === 404) {
      setNotFoundCid(res.data.cid);
    }
    if (res.status === 400) {
      setErrors(Object.values(res.data));
    }
  };
  const { form, submitting, onFinish } = useSubmit(submit, success, error);

  return (
    <Form
      form={form}
      requiredMark="optional"
      name="login"
      onFinish={onFinish}
      autoComplete="off"
      style={{ marginLeft: "2em", marginRight: "2em", maxWidth: 400 }}
      initialValues={{
        currency: "EUR",
        deposit_limit_period: "DAILY",
      }}
    >
      <Card
        style={{
          marginBottom: "2em",
          textAlign: "start",
          backgroundColor: "rgb(197 182 221)",
        }}
      >
        <Text>
          <b>Existing Customers:</b> Use your Customer ID to sign up to our
          Payment Portal for secure and easy payments.
          <br />
          <br />
          <b>
            To become a customer,{" "}
            <a href="https://www.fitzwilliamsports.com/apply-for-membership/">
              Apply Here
            </a>
          </b>
        </Text>
      </Card>
      <Form.Item
        name="cid"
        rules={[
          {
            required: true,
            message: "Please enter your Customer ID.",
          },
        ]}
      >
        <Input
          prefix={<IdcardOutlined className="site-form-item-icon" />}
          placeholder="Customer ID"
          autoCapitalize="none"
        />
      </Form.Item>

      <Form.Item
        name="email"
        rules={[{ required: true, message: "Please enter an email." }]}
      >
        <Input
          type="email"
          prefix={<MailOutlined className="site-form-item-icon" />}
          placeholder="Email"
          autoCapitalize="none"
        />
      </Form.Item>

      <Form.Item
        name="password1"
        rules={[{ required: true, message: "Please enter a password." }]}
      >
        <Input.Password
          prefix={<LockOutlined className="site-form-item-icon" />}
          placeholder="Password"
        />
      </Form.Item>

      <Form.Item
        name="password2"
        rules={[{ required: true, message: "Please confirm password." }]}
      >
        <Input.Password
          prefix={<LockOutlined className="site-form-item-icon" />}
          placeholder="Confirm Password"
        />
      </Form.Item>

      {errors.map((x, index) => (
        <Text type="danger" key={index}>
          {x}
        </Text>
      ))}

      <Form.Item
        name="remember"
        valuePropName="checked"
        style={{ marginTop: 20 }}
      >
        <Checkbox className="login-form">Remember me</Checkbox>
      </Form.Item>

      {notFoundCid && (
        <div style={{ marginBottom: 24 }}>
          <Space>
            <Text type="danger">
              Customer ID not found: {notFoundCid}.<br />
              Not a customer?{" "}
              <a href="https://www.fitzwilliamsports.com/apply-for-membership/">
                Apply Here
              </a>
            </Text>
          </Space>
        </div>
      )}

      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          size="large"
          loading={submitting}
        >
          Sign Up
        </Button>
      </Form.Item>
    </Form>
  );
};

export default RegisterForm;
