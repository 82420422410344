import React, { useContext, useState } from "react";
import { Form, Input, Button, Checkbox, Typography } from "antd";
import { LockOutlined } from "@ant-design/icons";
import { useSubmit } from "antdash";
import { useParams, useHistory } from "react-router-dom";
import UserContext from "../context/UserContext";
import userApi from "../api/user";

const { Text, Title } = Typography;

const ConfirmResetPasswordForm = () => {
  const [errors, setErrors] = useState([]);
  const { setUser } = useContext(UserContext);
  const { uid, token } = useParams();
  const history = useHistory();

  const submit = (formData) =>
    userApi.confirmResetPassword({
      uidb64: uid,
      token,
      password1: formData.password1,
      password2: formData.password2,
    });
  const success = (res, formData) => {
    setUser(res.data, formData.remember);
    history.push("/");
  };
  const error = (res) => {
    setErrors(Object.values(res.data));
  };
  const { form, submitting, onFinish } = useSubmit(submit, success, error);

  return (
    <Form
      form={form}
      name="login"
      onFinish={onFinish}
      autoComplete="off"
      style={{ marginLeft: "2em", marginRight: "2em", maxWidth: 300 }}
    >
      <div style={{ marginBottom: 20 }}>
        <Title level={4} style={{ color: "#fff" }}>
          Reset Password
        </Title>
      </div>

      <Form.Item
        name="password1"
        rules={[{ required: true, message: "Please enter a password." }]}
      >
        <Input.Password
          prefix={<LockOutlined className="site-form-item-icon" />}
          placeholder="New Password"
        />
      </Form.Item>

      <Form.Item
        name="password2"
        rules={[{ required: true, message: "Please confirm password." }]}
      >
        <Input.Password
          prefix={<LockOutlined className="site-form-item-icon" />}
          placeholder="Confirm New Password"
        />
      </Form.Item>

      {errors.map((x, index) => (
        <Text type="danger" key={index}>
          {x}
        </Text>
      ))}

      <Form.Item name="remember" valuePropName="checked">
        <Checkbox className="login-form">Remember me</Checkbox>
      </Form.Item>

      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          size="large"
          loading={submitting}
        >
          Sign In
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ConfirmResetPasswordForm;
