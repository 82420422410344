import React from "react";
import { Route } from "react-router-dom";

const AuthNavigator = ({ logo, logoAlt, routes }) => {
  return (
    <div className="login-page">
      {/* <img
        className="logo"
        src={logo}
        alt={logoAlt}
        style={{ height: 55, marginBottom: 30 }}
      /> */}
      {Object.values(routes).map((route) => (
        <Route
          key={route.path}
          path={route.path}
          exact={route.exact}
          component={route.component}
        />
      ))}
    </div>
  );
};

export default AuthNavigator;
